<template>

  <div class="container bg">
    <div style="padding: 0.625rem 1.25rem !important;display: flex;">
      <div style="width: 33%;">
        <el-tabs
          v-model="activeName"
          @tab-click="saveClick"
        >
          <el-tab-pane
            label="话术列表"
            name="list"
          ></el-tab-pane>
          <el-tab-pane
            label="添加话术"
            name="save"
          ></el-tab-pane>
        </el-tabs>
      </div>

      <div style="width: 33%;">
        <div
          v-if="showlist"
          class="df jc-sb bg-white head"
        >
          <div class="df">
            <div class="dfc mr50">
              <el-input
                v-model="minFormdata.videoName"
                placeholder="请输入互动话术名称"
              ></el-input>
            </div>
            <div class="df">
              <el-button
                class="blue-button"
                @click="getlive"
              >搜索</el-button>
              <!-- <el-button
                plain
                @click="clear"
              >清理</el-button> -->
            </div>
          </div>
        </div>
      </div>

      <div style="width: 33%;">
        <div class="df jc-sb bg-white head">
          <el-button
            v-if="showlist"
            icon="el-icon-delete"
            class="rignt_label"
            @click="clear"
          >清理</el-button>
          <!-- <el-button
            v-else
            type="primary"
            style="margin-left: auto;"
            @click="toAdd"
          >提交</el-button> -->
        </div>
      </div>
    </div>
    <div v-if="showlist">

      <div class="box">
        <div
          v-if="videoList.length > 0"
          class="df f-w jc-sb plr20"
          v-infinite-scroll="load"
        >
          <div
            v-for="(i, idx) in videoList"
            :key="idx"
            class="listBox item"
            @click="(activeInfo = i), (showlist = false)"
          >
            <img
              :src="i.interaction_image"
              alt=""
            />
            <div class="info">
              <div class="tit">
                <span class="Ttext">互动名称：</span>
                <span style="color: #ffffff;">{{ i.interaction_name }}</span>
              </div>

              <div class="tit"><span class="Ttext">创建时间：</span>
                <span
                  style="color: #ffffff;font-weight:400;">{{ i.create_time }}</span>

                <span
                  class="Ttext"
                  style="margin-left: 60px;"
                >互动视频数量：</span>
                <span>{{ i.videvo_ids_num }}个</span>

              </div>
              <div>
                <div class="Ttext mb5">互动关键词：</div>
                <div class="df f-w">
                  <div
                    v-for="(j, index) in i.keywords"
                    :key="index"
                    class="btn"
                  >{{ j }}</div>
                </div>
              </div>

            </div>
            <div
              class="tools"
              @click.stop="activeInfo = i"
            >
              <el-dropdown
                trigger="click"
                placement="bottom"
                @command="handleClick"
              >
                <span class="el-dropdown-link">
                  <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <!-- <el-dropdown-item command="edit" icon="el-icon-edit">编辑</el-dropdown-item> -->
                  <el-dropdown-item
                    command="del"
                    icon="el-icon-delete"
                  >删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <div v-else>
          <myempty text="互动话术"></myempty>
        </div>
      </div>
    </div>
    <div v-else>
      <create
        :id="activeInfo.id"
        ref="add"
        @back="back"
      ></create>
    </div>
  </div>
</template>
<script>
import { get_list, del } from "@/api/live.js";
import myempty from "@/components/myempty.vue";
import create from "./create.vue";
export default {
  components: {
    myempty,
    create,
  },
  data () {
    return {
      activeName: 'list',
      minFormdata: {
        videoStatus: "",
        videoName: "",
      },
      showlist: true,
      visible: false,
      dialogVisible: false,
      innerVisible: false,
      openLive: false,
      showStatus: false,
      disabled: false,
      isopen: false,
      fullscreenLoading: false,
      israndom: false,
      isAddr: false,
      isRoomId: false,
      ipt: "",
      id: "",
      title: "添加直播方案",
      player: null, // 用来存储当前 video
      inputLive: "",
      liveAddr: "",
      roomId: "", //直播房间号
      activeInfo: {},
      activeI: {},
      chooseRoom: "",
      liveInfo: {
        livelist: [],
        keywordList: [],
      },
      downloadList: [],
      isPlay: false,
      videoList: [],
      page:1,
      pageNum:1,
      perPage:8,
    };
  },
  mounted () {
    this.getlive();
  },
  methods: {
    saveClick (tab, event) {
      // this.showlist = 
      if (event.srcElement.id == 'tab-save') {
        this.showlist = false
      } else {
        this.showlist = true
      }
      console.log(this.showlist);
    },
    back () {
      this.showlist = true;
      this.activeName = 'list';
      this.getlive();
    },
    toAdd () {
      this.activeInfo = {};
      this.showlist = false;
      this.$refs.add.getlive();
    },
    add () {
      if (!this.ipt) {
        this.$message.error("请输入直播方案名称");
        return;
      }
      this.ipt = "";
    },
    clear () {
      this.minFormdata = {
        videoName: "",
      };
      this.getlive();
    },
    getlive () {
      let params = {
        page: this.page,
        item: this.perPage,
        so: this.minFormdata.videoName || "",
      };
      get_list(params).then((res) => {
        if (res.code == "200") {
          // this.total = res.data.page_num;
          // this.videoList = res.data.list;
          if (this.pageNum != 1){
            this.videoList.push(...res.data.list);
            this.pageNum = res.data.page_num;
            console.log(this.videoList);
          }else {
            this.videoList = res.data.list
            this.pageNum = res.data.page_num;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    load() {
      if (this.page < this.pageNum) {
        this.page++;
        this.getlive();
      }
    },
    handleClick (command) {
      this.type = command;
      switch (command) {
        case "edit":
          this.showlist = false;
          break;
        case "del":
          this.$confirm("此操作将永久删除该视频, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then((_) => {
              del({
                interaction_id: this.activeInfo.id,
              }).then((res) => {
                if (res.code == "200") {
                  this.$message.success(res.msg);
                  this.getlive();
                } else {
                  this.$message.error(res.msg);
                }
              });
            })
            .catch((_) => { });
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-tabs__active-bar {
  background-color: #04ddb5;
}
/deep/.el-tabs__item:hover {
  color: #04ddb5;
}
/deep/.el-tabs__item.is-active {
  color: #04ddb5;
}
/deep/.el-tabs__item.is-active {
  color: #04ddb5;
}
/deep/.el-input__inner {
  border: 0;
  color: #ffffff;
  background: #f2f3f5;
}
/deep/.el-tabs__item.is-active {
  color: rgba(4, 221, 181, 1);
}
/deep/.el-tabs__item {
  color: #ffffff;
}
/deep/.el-tabs__nav-wrap::after {
  background-color: #ffffff00;
}
/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #f2f3f5;
}
.rignt_label {
  background-color: #ffffff00;
  border-style: none;
  color: #ffffff;
  font-size: 14px;
  margin-left: auto;
}
.blue-button {
  border-radius: 5px;
  border-style: none;
  width: 80px;
  height: 40px;
  background: linear-gradient(90deg, #36f3aa 0%, #04ddb5 100%);
  border-radius: 10px;
  color: #202532;
}
.listBox {
  background: #202532;
  width: 49%;
  padding: 20px;
  display: flex;
  margin-bottom: 15px;
  border-radius: 8px;

  .info {
    margin-left: 20px;
    text-align: left;
    width: 86%;
    padding: 10px;

    .tit {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .btn {
      background: #434959;
      padding: 0.0625rem 0.4375rem;
      color: #ffffff;
      border: 0.0625rem solid rgba(67, 73, 89, 1);
      border-radius: 8px;
      margin-right: 0.375rem;
    }
  }

  img {
    width: 99px;
    height: 146px;
    border-radius: 8px;
    overflow: hidden;
  }
}

// .listBox:last-child {
//   margin-bottom: 0;
// }

/deep/.el-dialog__header {
  padding: 20px 20px 10px;
  text-align: left;
  font-weight: bold;
}

.el-select {
  width: 100%;
}

/deep/.el-dialog {
  border-radius: 8px;
}
/deep/.el-dropdown {
  color: #ffffff;
}

.item {
  .desc {
    padding: 10px;
    text-align: left;
  }
  .tools {
    position: relative;
    z-index: 99;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background: rgba(0, 0, 0, 1);
    border-radius: 4px;
    // border: 1px solid #c8dfff;
    top: 0px;
    left: 5px;
  }
}

.title {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
}

.imgBg {
  img {
    width: 175px;
    height: 275px;
  }
}

.info {
  padding-top: 5px;
  padding-bottom: 5px;

  /deep/.el-pagination {
    padding: 5px 5px 0 !important;
  }
}

.voxBox {
  .btns {
    padding: 15px;
    background: #f1f4f9;
    height: 245px;
    overflow: scroll;

    /deep/.el-button {
      padding: 10px !important;
      margin-bottom: 10px;
      margin-left: 0 !important;
    }

    img {
      width: 35px;
      height: 35px;
      border-radius: 50px;
      margin-right: 7px;
    }
  }

  .bottom {
    padding: 10px 20px;
  }
}
</style>
