<template>
  <div class="container bg">
    <div style="width: 100%;display: flex;position: relative;">
      <div style="width: 50%;">
        <el-tabs
          v-model="activeName"
          @tab-click="saveClick"
        >
          <el-tab-pane
            label="直播视频"
            name="list"
          ></el-tab-pane>
          <el-tab-pane
            label="互动话术"
            name="save"
          ></el-tab-pane>
        </el-tabs>
      </div>

      <div style="width: 45%;">
        <div class="df jc-sb bg-white head">
          <el-button
            class="rignt_label"
            @click="back"
          >返回</el-button>
          <!-- <el-button
            v-else
            type="primary"
            style="margin-left: auto;"
            @click="toAdd"
          >提交</el-button> -->
        </div>
      </div>

    </div>
    <el-container>
      <!-- <el-header
        class="bg-white"
        style="line-height: 60px"
      >
        <div class="dfc">
          <div class="df">
            <div
              class="back"
              @click="back"
            >
              <img
                src="~@/assets/img/back1.png"
                alt=""
              />
            </div>
            <div class="twoMenubox">
              <div
                v-for="(i, idx) in menu"
                :key="idx"
                class="menu"
                :class="current == idx ? 'active' : ''"
                @click="menuHandle(i, idx)"
              >
                <img
                  :src="current == idx ? i.icon : i.activeicon"
                  alt=""
                />
                <div>{{ i.title }}</div>
              </div>
            </div>
          </div>

          <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            @click="showAdd = true"
          >添加</el-button>
        </div>
      </el-header> -->
      <el-main>
        <div class="box">
          <div class="vlist">
            <div v-if="current == 0">

              <div style="display: flex; ">
                <div
                  class="video_add"
                  @click="showAdd = true"
                >
                  <div class="add"></div>
                </div>
                <div
                  v-if="evideoList.length > 0"
                  v-infinite-scroll="liveload"
                  class="df f-w"
                  style="margin-left: 30px;"
                >
                  <div
                    v-for="(item, idx) in evideoList"
                    :key="idx"
                    class="imgBox"
                    @click="getItem(item)"
                  >
                    <img
                      :src="item.video_image"
                      object-fit="cover"
                      alt=""
                      style="background-color: #243748;border-radius: 10px;"
                    />
                    <div class="desc">
                      {{ item.title }}
                      <div><i class="el-icon-time"></i>{{ item.create_time }}
                      </div>
                    </div>
                    <div
                      class="tools"
                      style="color: #ff5733"
                      @click.stop="delitem(item)"
                    >
                      <i class="el-icon-delete"></i>
                    </div>
                  </div>
                </div>
                <!-- <div v-else>
                  <myempty text="视频"></myempty>
                </div> -->
              </div>

            </div>
            <div v-if="current == 1">
              <div style="">
                <div
                  class="title_add"
                  @click="showAdd = true"
                >
                  <div class="add"></div>
                </div>

                <div
                  v-if="ivideoList.length > 0"
                  v-infinite-scroll="sayload"
                  class=""
                  style="width: 100%;display: flex;flex-wrap: wrap;"
                >
                  <div
                    v-for="(i, idx) in ivideoList"
                    :key="idx"
                    style="width: 50%;"
                  >

                    <div class="listBox1">
                      <img
                        :src="i.interaction_image"
                        alt=""
                      />
                      <div class="info">
                        <div class="tit"><span class="Ttext">互动名称：<span
                              class="name"
                            >{{ i.interaction_name }}</span></span></div>
                        <div>
                          <div class="Ttext mb5">互动关键词：</div>
                          <div class="df f-w">
                            <div
                              v-for="(j, index) in i.keywords"
                              :key="index"
                              class="btn"
                            >{{ j }}</div>
                          </div>
                        </div>
                        <div class="mt10">
                          <span style="color: white!important;"><span class="Ttext">互动视频数量</span><span style="color: white!important;" class="name">{{ i.videvo_ids_num }}个</span></span>
                          <span style="margin-left: 20px"><span
                              class="Ttext">创建时间：<span
                                class="name">{{ i.create_time }}</span></span></span>
                        </div>
                      </div>
                      <div
                        class="tools"
                        @click.stop="delitem(i)"
                      >
                        <i class="el-icon-delete"></i>
                      </div>

                    </div>

                  </div>
                </div>
                <!-- <div v-else>
                <myempty text="互动话术"></myempty>
              </div> -->
              </div>

            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-dialog
      :title="`${current == 0 ? '添加讲解视频' : '选择互动话术'}`"
      :visible.sync="showAdd"
      width="70%"
      :show-close="false"
    >
      <!-- 搜索框 -->
      <div class="ipt">
        <el-input
          v-model="search"
          placeholder="输入关键词搜索视频"
          suffix-icon="el-icon-search"
          @blur="handleSelect"
          @keyup.enter.native="handleSelect"
        ></el-input>
      </div>
      <div class="dlistBox">
        <el-checkbox-group v-model="checkList">
          <!--          TODO这里是直播视频展示页-->
          <div v-if="current == 0">
            <div
              v-if="videoList.length > 0"
              v-infinite-scroll="live_load"
              class="df f-w"
            >
              <div
                v-for="(item, idx) in videoList"
                :key="idx"
                class="imgBox"
                @click="getItem(item)"
              >
                <img
                  :src="item.video_image"
                  object-fit="cover"
                  alt=""
                  style="background-color: #243748;border-radius: 10px;"
                />
                <div class="desc">
                  {{ item.title }}
                  <div><i class="el-icon-time"></i>{{ item.create_time }}</div>
                </div>
                <div
                  class="tools"
                  @click.stop="activeI = item"
                >
                  <div class="check">
                    <el-checkbox
                      :key="item.id"
                      :label="item.id"
                    ><br /></el-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <myempty text="视频"></myempty>
            </div>
          </div>
          <!--          TODO这里是互动话术展示页-->
          <div
            v-else
            v-infinite-scroll="say_load"
            class="info"
          >
            <div v-if="sayVideoList.length > 0">
              <div
                v-for="(i, idx) in sayVideoList"
                :key="idx"
                class="listBox1"
              >
                <div class="check mr10">
                  <el-checkbox
                    :key="i.id"
                    :label="i.id"
                  ><br /></el-checkbox>
                </div>
                <img
                  :src="i.interaction_image"
                  alt=""
                />
                <div class="info">
                  <div class="tit">
                    <span class="Ttext">互动名称：<span
                        class="name">{{ i.interaction_name }}</span></span>
                  </div>
                  <div>
                    <div class="Ttext mb5">互动关键词：</div>
                    <div class="df f-w">
                      <div
                        v-for="(j, index) in i.keywords"
                        :key="index"
                        class="btn"
                      >{{ j }}</div>
                    </div>
                  </div>
                  <div class="mt5">
                    <span style="color: white;"><span  class="Ttext">互动视频数量</span>{{ i.videvo_ids_num }}个</span>
                    <span style="margin-left: 20px"><span
                        class="Ttext">创建时间：</span>{{ i.create_time }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <myempty text="互动话术"></myempty>
            </div>
          </div>
        </el-checkbox-group>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="showAdd = false">取 消</el-button>
        <el-button
          type="primary"
          @click="additem"
        >添 加</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { goods_query_data, goods_del_data, goods_add_data, get_list } from "@/api/live.js";
import { getVideo } from "@/api/video.js";
import myempty from "@/components/myempty.vue";
export default {
  components: {
    myempty,
  },
  props: {
    info: {
      type: Object,
      default () {
        return {};
      },
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      activeName: 'list',
      form: {
        name: "",
        videoName: "",
      },
      showAdd: false,
      current: 0,
      menu: [
        {
          icon: require("@/assets/img/secondMenu/video1.png"),
          activeicon: require("@/assets/img/secondMenu/video.png"),
          title: "直播视频",
          pageName: "video",
          params: {},
        },
        {
          icon: require("@/assets/img/secondMenu/hudong1.png"),
          activeicon: require("@/assets/img/secondMenu/hudong.png"),
          title: "互动话术",
          pageName: "shortVideo",
          params: {},
        },
      ],
      search: "",
      visible: false,
      dialogVisible: false,
      innerVisible: false,
      ipt: "",
      title: "添加直播方案",
      player: null, // 用来存储当前 video
      inputLive: "",
      liveAddr: "",
      roomId: "", //直播房间号
      activeInfo: {},
      activeI: {},
      chooseRoom: "",
      isPlay: false,
      checkList: [],
      videoList: [], //添加视频列表
      sayVideoList: [], //互动视频列表
      evideoList: [], //商品视频
      ivideoList: [], //直播互动
      interactionArr: [], //互动话术列表
      allPerPage: 12, // 所有每页展示数据
      livePage: 1,   // 直播视频页码
      livePageNum: 1,  // 直播视频页码总数
      liveAddPage: 1,  // 直播添加视频页码
      liveAddPageNum: 1,
      sayPage: 1,
      sayPageNum: 1,
      sayAddPage: 1,
      sayAddPageNum: 1,
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    saveClick (tab, event) {
      // this.showlist = 
      if (event.srcElement.id == 'tab-save') {
        this.current = 1;
        this.search = '';
      } else {
        this.current = 0;
        this.search = ''
      }
      console.log(this.current);
      this.getList(1);
      this.getVideo();
    },
    init () {
      this.getList();
      this.getVideo();
    },
    back () {
      this.$emit("back");
    },
    handleSelect () {
      console.log(this.search);
      this.getVideo();
    },
    menuHandle (e, idx) {
      console.log(e, idx)
      this.current = e ? idx : 0;
      this.getList(idx);
      this.getVideo();
    },
    // 直播视频和互动话术分页数据获取
    getList () {
      const loading = this.$loading({
        lock: true,
        text: "获取数据中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.current == 0) {
        goods_query_data({
          so: this.search,
          page: this.livePage,
          item: this.allPerPage,
          goods_id: this.info.id,
          type: this.current + 1,
        }).then((res) => {
          if (res.code == "200") {
            loading.close();
            if (this.livePage != 1) {
              this.evideoList.push(...res.data.list);
              this.livePageNum = res.data.page_num;
            } else {
              this.evideoList = res.data.list;
              this.livePageNum = res.data.page_num;
            }
          } else {
            loading.close();
            this.$message.error(res.msg);
          }
        });
      } else {
        goods_query_data({
          so: this.search,
          page: this.sayPage,
          item: this.allPerPage,
          goods_id: this.info.id,
          type: this.current + 1,
        }).then((res) => {
          if (res.code == "200") {
            loading.close();
            if (this.sayPage != 1) {
              this.ivideoList.push(...res.data.list);
              this.sayPageNum = res.data.page_num;
            } else {
              this.ivideoList = res.data.list;
              this.sayPageNum = res.data.page_num;
            }
          } else {
            loading.close();
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 添加视频直播,添加互动话术获取方法
    getVideo () {
      const loading = this.$loading({
        lock: true,
        text: "获取数据中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.current == 0) {
        getVideo({
          so: this.search,
          page: this.liveAddPage,
          item: this.allPerPage,
          statusa: 3,
          type_s: 2,
        }).then((res) => {
          if (res.code == "200") {
            loading.close();
            if (this.liveAddPage != 1) {
              this.videoList.push(...res.data.list);
              this.liveAddPageNum = res.data.page_num;
            } else {
              this.videoList = res.data.list;
              this.liveAddPageNum = res.data.page_num;
            }
          } else {
            loading.close();
            this.$message.error(res.msg);
          }
        });
      } else {
        get_list({
          so: this.search,
          page: this.sayAddPage,
          item: this.allPerPage,
        }).then((res) => {
          if (res.code == "200") {
            loading.close();
            if (this.sayAddPage != 1) {
              this.sayVideoList.push(...res.data.list);
              this.sayAddPageNum = res.data.page_num;
            } else {
              this.sayVideoList = res.data.list;
              this.sayAddPageNum = res.data.page_num;
            }
          } else {
            loading.close();
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 添加视频滚动事件加载函数
    live_load () {
      console.log("视频滚动事件加载函数")
      if (this.liveAddPage < this.liveAddPageNum) {
        this.liveAddPage++;
        this.getVideo();
      }
    },
    // 添加话术滚动事件加载函数
    say_load () {
      console.log("话术滚动事件加载函数")
      if (this.sayAddPage < this.sayAddPageNum) {
        this.sayAddPage++;
        this.getVideo();
      }
    },
    liveload () {
      console.log("视频滚动事件加载函数")
      if (this.livePage < this.livePageNum) {
        this.livePage++;
        this.getList();
      }
    },
    sayload () {
      console.log("互动滚动事件加载函数")
      if (this.sayPage < this.sayPageNum) {
        this.sayPage++;
        this.getList();
      }
    },
    delitem (i) {
      this.$confirm("是否删除该视频?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((_) => {
          goods_del_data({
            goods_id: this.info.id,
            type: this.current + 1,
            data_id: i.id,
          }).then((res) => {
            if (res.code == "200") {
              this.$message.success(res.msg);
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => { });
    },
    additem () {
      let arr = [];
      this.checkList.map((i) => {
        arr.push(i + "");
      });
      console.log(arr);
      goods_add_data({
        goods_id: this.info.id,
        type: this.current + 1,
        data: arr,
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.getList();
          this.checkList = [];
          this.showAdd = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #f2f3f5;
}

/deep/.el-dialog__body {
  padding: 20px;
}
/deep/.el-tabs__active-bar {
  background-color: #04ddb5;
}
/deep/.el-tabs__item:hover {
  color: #04ddb5;
}
/deep/.el-tabs__item.is-active {
  color: #04ddb5;
}
/deep/.el-tabs__item.is-active {
  color: #04ddb5;
}
/deep/.el-input__inner {
  border: 0;
  color: #ffffff;
  background: #f2f3f5;
}
/deep/.el-tabs__item.is-active {
  color: rgba(4, 221, 181, 1);
}
/deep/.el-tabs__item {
  color: #ffffff;
}
/deep/.el-tabs__nav-wrap::after {
  background-color: #ffffff00;
}
/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #f2f3f5;
}
.rignt_label {
  background: linear-gradient(
    90deg,
    rgba(25, 74, 249, 1) 0%,
    rgba(63, 0, 222, 1) 100%
  );
  border-radius: 10px;
  border-style: none;
  height: 40px;
  color: #ffffff;
  font-size: 14px;
  margin-left: auto;
}
.blue-button {
  border-radius: 5px;
  border-style: none;
  width: 80px;
  height: 40px;
  background: linear-gradient(90deg, #36f3aa 0%, #04ddb5 100%);
  border-radius: 10px;
  color: #202532;
}
.back {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  background: #f2f7fe;
  margin-top: 8px;
  line-height: 45px;

  img {
    width: 24px;
    height: 24px;
  }
}
.title_add {
  left: 0px;
  top: 0px;
  width: 725px;
  height: 240px;
  opacity: 1;
  border-radius: 16px;
  background: rgba(15, 19, 25, 0.5);
  border: 2px solid rgba(255, 255, 255, 0.3);
  line-height: 240px;
  .add {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #d4d7d7;
    position: relative;
    cursor: pointer;
    &:hover {
      // hover状态
      background-color: #00c8c8;
    }
    &.disabledBtn {
      // 禁用状态
      background-color: #e4e4e4;
      cursor: not-allowed;
    }
  }
  .add::before {
    // 加号的横线
    content: "";
    width: 8px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: 7px;
    left: 4px;
  }
  .add::after {
    // 加号的竖线
    content: "";
    width: 2px;
    height: 8px;
    background-color: #fff;
    position: absolute;
    top: 4px;
    left: 7px;
  }
}
.video_add {
  left: 0px;
  top: 0px;
  width: 208px;
  height: 412px;
  opacity: 1;
  border-radius: 16px;
  background: rgba(15, 19, 25, 0.5);
  border: 2px solid rgba(255, 255, 255, 0.3);
  line-height: 412px;
  .add {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #d4d7d7;
    position: relative;
    cursor: pointer;
    &:hover {
      // hover状态
      background-color: #00c8c8;
    }
    &.disabledBtn {
      // 禁用状态
      background-color: #e4e4e4;
      cursor: not-allowed;
    }
  }
  .add::before {
    // 加号的横线
    content: "";
    width: 8px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: 7px;
    left: 4px;
  }
  .add::after {
    // 加号的竖线
    content: "";
    width: 2px;
    height: 8px;
    background-color: #fff;
    position: absolute;
    top: 4px;
    left: 7px;
  }
}
.listBox {
  background: #fff;
  text-align: left;
  width: 99%;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.listBox1 {
  width: 725px;
  height: 240px;
  opacity: 1;
  border-radius: 10px;
  background: rgba(21, 26, 48, 1);
  box-shadow: 20px 13.33px 40px -20px rgba(0, 0, 0, 0.3);

  margin-bottom: 30px;

  // background: #fff;
  // width: 100%;
  padding: 15px;
  margin: 0 0 20px;
  // border-radius: 2px;
  display: flex;
  // margin-left: 30px;
  margin-top: 30px;

  .info {
    margin-left: 20px;
    text-align: left;
    width: 86%;
    .name {
      color: #ffffff;
    }
    .tit {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .btn {
      background: #d9edf5;
      padding: 1px 7px;
      color: #4284cf;
      border: 1px solid #4284cf;
      border-radius: 4px;
      margin-right: 6px;
    }
  }

  img {
    width: 90px;
    height: 160px;
    border-radius: 8px;
    overflow: hidden;
  }

  .tools {
    position: relative;
    z-index: 99;
    width: 27px;
    height: 27px;
    color: #ffffff;
    line-height: 27px;
    // background: #fff;
    // border: 1px solid #c8dfff;
    border-radius: 4px;
    top: 0px;
    left: 0px;
  }
}

.listBox1:last-child {
  margin-bottom: 0;
}

.twoMenubox {
  display: flex;
  justify-content: space-between;
  padding-top: 0;

  .menu {
    display: flex;
    align-items: center;
    background: #f2f7fe;
    color: #8b9ab4;
    padding: 10px 8px;
    margin: 8px 10px;
    border-radius: 6px;
    justify-content: space-around;
    width: 130px;
    height: 45px;

    img {
      margin-right: -8px;
      width: 16px;
      height: 18px;
    }
  }

  .active {
    color: #fff !important;
    background: #015ce6;
    margin: 8px 10px;
    border-radius: 6px;
  }

  img {
    width: 18px;
    height: 18px;
  }
}

.vlist {
  .info {
    padding-top: 10px;
  }
  /deep/.el-tabs__header {
    margin: 0 !important;
    padding: 10px;
    background: #fff;
  }

  /deep/.el-tabs__nav-wrap::after {
    height: 0 !important;
  }
}

.ipt {
  width: 32%;
  position: absolute;
  top: 3%;
  right: 2%;

  /deep/.el-input__inner {
    background: #f1f4f9 !important;
  }
}

.dlistBox {
  height: 59vh;
  overflow: scroll;
  padding: 10px 5px 0;
  margin-bottom: -20px;
  .tools {
    border: 0 !important;
    background: transparent;
  }
  .check {
    align-self: center;
  }
  /deep/.el-checkbox-group {
    font-size: 15px !important;
  }
  /deep/.el-checkbox__inner {
    width: 18px;
    height: 18px;
  }
  /deep/.el-checkbox__inner::after {
    height: 10px;
    left: 6px;
  }
  .imgBox {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
    .img {
      background-color: #243748;
      border-radius: 10px;
    }
  }
  .imgBox:nth-child(5n) {
    margin-right: 0;
  }
  .imgBox:nth-child(6n) {
    margin-right: 10px;
  }
}
</style>
