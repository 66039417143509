<template>
  <div
    class="bg"
    style="height: 92vh"
  >

    <el-container
      style="background-color: rgba(32, 37, 50, 1); border-radius: 16px;"
    >
      <el-header
        class="bg-white"
        style="line-height: 60px"
      >
        <div class="dfc">
          <el-button
            class="save_button"
            @click="submit"
          >提交</el-button>
        </div>
      </el-header>
      <el-main>
        <div class="box">
          <div
            class="listBox"
            style="display: flex;"
          >
            <div class="Ttext mb10">互动话术</div>
            <div style="width: 320px">
              <el-input
                v-model="form.name"
                class="huashu"
                placeholder="请输入互动话术标题"
              ></el-input>
            </div>
          </div>

          <div class="listBox">
            <div
              style="display: flex; position: relative;"
              class="add_video"
            >
              <div class="Ttext mb10">触发关键词</div>
              <div style="position: absolute;right: 20px;margin-top: -5px;">
                <el-button
                  icon="el-icon-circle-plus-outline"
                  @click="addKey"
                >添加关键词</el-button>
              </div>
            </div>
            <div style="width: 100%; display: flex; flex-wrap: wrap;">

              <div
                v-for="(i, idx) in keywordList"
                :key="idx"
                style="width: 33%;margin-top: 10px;"
              >
                <div
                  class="df mb10"
                  style="width: 360px"
                >
                  <el-input
                    v-model="keywordList[idx]"
                    placeholder="请输入互动话术标题"
                  ></el-input>
                  <el-button
                    style="margin-left: 20px"
                    type="danger"
                    icon="el-icon-delete"
                    @click="delKey(idx)"
                  >删除</el-button>
                </div>
              </div>
            </div>

          </div>

          <div>
            <div
              class="listBox"
              style="padding: 10px"
            >
              <div
                style="display: flex; position: relative;"
                class="add_video"
              >
                <div class="Ttext">互动视频</div>
                <div style="position: absolute;right: 20px;margin-top: -10px;">
                  <el-button
                    icon="el-icon-circle-plus-outline"
                    @click="showAdd = true"
                  >添加视频</el-button>
                </div>
              </div>

              <!-- <div class="Ttext">互动视频</div>
              <el-button
                type="primary"
                icon="el-icon-circle-plus-outline"
                @click="showAdd = true"
              >添加视频</el-button> -->
            </div>
            <div>
              <div
                v-if="chooseList.length > 0"
                class="df f-w"
              >
                <div
                  v-for="(item, idx) in chooseList"
                  :key="idx"
                  class="imgBox"
                  @click="getItem(item)"
                >
                  <img
                    :src="item.video_image"
                    object-fit="cover"
                    alt=""
                    style="background-color: #243748;border-radius: 10px;"
                  />
                  <div class="desc">
                    {{ item.title }}
                    <div><i class="el-icon-time"></i>{{ item.create_time }}
                    </div>
                  </div>
                  <div
                    class="tools"
                    @click.stop="delItem(item)"
                  >
                    <i class="el-icon-delete"></i>
                  </div>
                </div>
              </div>
              <div
                v-if="chooseList.length == 0"
                class="df f-w"
              >
                <myempty
                  text="视频"
                  style="width: 100%"
                ></myempty>
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-dialog
      :title="`选择视频添加`"
      :visible.sync="showAdd"
      width="70%"
      :show-close="false"
    >
      <!-- 搜索框 -->
      <div class="ipt">
        <el-input
          v-model="search"
          placeholder="输入关键词搜索视频"
          suffix-icon="el-icon-search"
          @blur="handleSelect"
          @keyup.enter.native="handleSelect"
        ></el-input>
      </div>
      <div
        v-if="videoList.length > 0"
        class="dlist"
      >
        <el-checkbox-group
          v-model="checkList"
          v-infinite-scroll="video_load"
        >
          <div class="dialog">
            <div
              v-for="(i, idx) in videoList"
              :key="idx"
              class="listBox1"
            >
              <div class="check mr10">
                <el-checkbox
                  :key="i.id"
                  :label="i.id"
                ><br /></el-checkbox>
              </div>
              <img
                :src="i.video_image"
                alt=""
              />
              <div class="info">
                <div class="tit">{{ i.title }}</div>
                <div>
                  <span>视频形象：{{ i.video_name }}</span>
                  <span style="margin-left: 20px">声音：{{ i.timbre_name }}</span>
                </div>
                <div class="i_bottom">
                  <span>时长：{{ i.times }}</span>
                  <span
                    style="margin-left: 20px">创建时间：{{ i.create_time }}</span>
                </div>
              </div>
            </div>
          </div>
        </el-checkbox-group>
      </div>
      <div v-else>
        <el-empty description="暂无视频"></el-empty>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="showAdd = false">取 消</el-button>
        <el-button
          type="primary"
          @click="addItem"
        >添加</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getVideo } from "@/api/video.js";
import { add, edit, get_one } from "@/api/live.js";
import myempty from "@/components/myempty.vue";
export default {
  components: {
    myempty,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      form: {
        name: "",
      },
      search: "",
      ipt: "",
      showList: true,
      showAdd: false,
      dialogVisible: false,
      isPlay: false,
      keywordList: [""],
      chooseList: [],
      checkList: [],
      activeInfo: {},
      activeI: {},
      videoList: [],
      pageNum: 1,
      videoPage: 1,
      videoPerPage: 8,
      onePage: 1,
      onePageNum: 1,
      onePerPage: 8
    };
  },
  mounted () {
    if (this.id != "") {
      this.get_one();
    }
    this.getList();
  },
  methods: {
    back () {
      this.$emit("back");
    },
    addKey () {
      this.keywordList.push("");
    },
    delKey (idx) {
      this.keywordList.splice(idx, 1);
    },
    handleSelect () {
      console.log(11);
    },
    addItem () {
      console.log(this.checkList);
      this.chooseList = [];
      this.videoList.map((i) => {
        this.checkList.map((j) => {
          if (i.id == j) {
            this.chooseList.push(i);
          }
        });
      });
      console.log(this.chooseList);
      this.showAdd = false;
    },
    delItem (item) {
      this.$nextTick(() => {
        this.chooseList.map((i, idx) => {
          if (i.id == item.id) {
            this.chooseList.splice(idx, 1);
          }
        });
      });
    },
    getList1 () {
      getVideo({
        page: 1,
        item: 1000,
        statusa: 3,
        type_s: 2,
      }).then((res) => {
        if (res.code == "200") {
          this.videoList = res.data.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    getList () {
      const loading = this.$loading({
        lock: true,
        text: "获取数据中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getVideo({
        page: this.videoPage,
        item: this.videoPerPage,
        statusa: 3,
        type_s: 2,
      }).then((res) => {
        if (res.code == "200") {
          loading.close();
          if (this.videoPage != 1) {
            this.videoList.push(...res.data.list);
            this.pageNum = res.data.page_num;
          } else {
            this.videoList = res.data.list;
            this.pageNum = res.data.page_num;
          }
        } else {
          loading.close();
          this.$message.error(res.msg);
        }
      });
    },
    video_load () {
      if (this.videoPage < this.pageNum) {
        this.videoPage++;
        this.getList();
      }
    },
    get_one (id) {
      const loading = this.$loading({
        lock: true,
        text: "获取数据中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      get_one({
        page: this.onePage,
        item: this.onePerPage,
        interaction_id: this.id,
      }).then((res) => {
        if (res.code == "200") {
          loading.close();
          this.keywordList = [];
          this.chooseList = [];
          this.form.name = res.data.interaction_name;
          if (this.page != 1) {
            this.keywordList.push(...res.data.keywords);
            this.chooseList.push(...res.data.videvo_data);
          } else {
            this.keywordList = res.data.keywords;
            this.chooseList = res.data.videvo_data;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    submit () {
      let arr = [];
      this.checkList.map((i) => {
        arr.push(i + "");
      });
      if (this.id != "") {
        edit({
          interaction_id: this.id,
          keywords: this.keywordList,
          interaction_name: this.form.name,
          videvo_ids: arr,
        }).then((res) => {
          if (res.code == "200") {
            this.$message.success(res.msg);
            this.id = "";
            setTimeout(() => {
              this.back();
            }, 800);
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        add({
          keywords: this.keywordList,
          interaction_name: this.form.name,
          videvo_ids: arr,
        }).then((res) => {
          if (res.code == "200") {
            this.$message.success(res.msg);
            setTimeout(() => {
              this.back();
            }, 800);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.huashu {
  border-radius: 10px;
  background: rgba(15, 19, 25, 1);
  border: 1px solid rgba(45, 50, 64, 1);
  margin-top: -20px;
  margin-left: 20px;
  /deep/ .el-input__inner {
    border: 0;
    color: #e8e8e8;
    background: #000000;
  }
}

/deep/.el-dialog {
  background: rgba(45, 50, 64, 1);
}
/deep/.listBox1 {
  background: rgba(45, 50, 64, 1);
}
/deep/.el-checkbox__inner {
}
.back {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  background: #f2f7fe;
  margin-top: 8px;
  line-height: 45px;

  img {
    width: 24px;
    height: 24px;
  }
}
.listBox {
  // background: #fff;
  text-align: left;
  width: 99%;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  /deep/ .el-input__inner {
    border: 0;
    color: #939dae;
    background: #000000;
  }
  .add_video {
    /deep/ .el-button {
      background: #fff0;
      color: #ffffff;
      border: 0.0625rem solid #ffffff;
      border-radius: 10px;
    }
  }
}

.listBox1 {
  background: rgba(21, 26, 48, 1);
  width: 99%;
  padding: 15px;
  margin: 0 0 20px;
  display: flex;
  border-bottom: 2px solid rgba(8, 1, 20, 1);
  align-items: center;
  border-radius: 15px;

  .info {
    margin-left: 20px;
    text-align: left;
    width: 86%;
    color: white;

    .tit {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .btn {
      background: #d9edf5;
      padding: 1px 7px;
      color: #4284cf;
      border: 1px solid #4284cf;
      border-radius: 4px;
      margin-right: 6px;
    }
  }

  /deep/.el-checkbox__inner {
    width: 20px !important;
    height: 20px !important;
  }
  /deep/.el-checkbox__inner::after {
    height: 12px !important;
    left: 8px !important;
  }

  img {
    width: 90px;
    height: 130px;
    border-radius: 8px;
    overflow: hidden;
  }
}

.listBox1:last-child {
  margin-bottom: 0;
  border-bottom: 0px;
}

.dlist {
  height: 59vh;
  overflow: scroll;
  padding: 10px 5px 0;
  margin-bottom: -20px;
}

.ipt {
  width: 32%;
  position: absolute;
  top: 3%;
  right: 2%;

  // /deep/.el-input__inner {
  //   background: #f1f4f9 !important;
  // }
}
.dfc {
  position: relative;
  left: 45%;
  top: -70px;
}
.save_button {
  background: linear-gradient(
    90deg,
    rgba(25, 74, 249, 1) 0%,
    rgba(63, 0, 222, 1) 100%
  );
  border: 0;
  color: #ffffff;
  border-radius: 10px;
}
.Ttext {
  color: #ffffff;
}
/deep/.el-dialog__title {
  color: #7a89a7;
}
/deep/ .el-button--danger {
  background-color: rgba(0, 0, 0, 0.6);
  border: 0;
}
</style>


<!-TODO--->